import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@app/shared-module/shared.module';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {NavigationItemComponent} from '@app/design-module/components/navigation-item/navigation-item.component';
import {TailwindTableComponent} from '@app/design-module/components/tailwind-table/tailwind-table.component';
import {TailwindModalComponent} from '@app/design-module/components/tailwind-modal/tailwind-modal.component';
import {TailwindDatePickerComponent} from '@app/design-module/components/tailwind-date-picker/tailwind-date-picker.component';
import {TailwindInjectorDirective} from '@app/design-module/directives/tailwind-injector.directive';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule
  ],
  declarations: [
    NavigationItemComponent,
    TailwindTableComponent,
    TailwindModalComponent,
    TailwindDatePickerComponent,
    TailwindInjectorDirective,
  ],
  providers: [],
  exports: [
    NavigationItemComponent,
    TailwindTableComponent,
    TailwindModalComponent,
    TailwindDatePickerComponent,
    TailwindInjectorDirective,
  ]
})
export class DesignModule {

}
