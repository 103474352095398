<div class="login-page" [@routerTransition]>
  <div class="row justify-content-center h-100 m-0">

    <div class="col-6 p-0 align-self-center left-bg">
      <div class="col-12">
        <img [src]="logoPath" width="150px" class="user-avatar"/>
        <div class="mt-4 text-theme header-font">{{'root.login: Accounts Management Tool' | translate}}</div>
      </div>
    </div>

    <div class="col-6 p-0">
      <div class="row justify-content-end bg-theme m-0">
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/americanFlag.svg" height="14px" width="20px"/></span><a
          class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('en')">English</a>
        </div>
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/></span><a
          class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('es')">Spanish</a>
        </div>
      </div>

      <div class="full-height bg-theme">
        <form class="col-12" *ngIf="token && !passwordChanged" role="form" [formGroup]="formGroup">
          <div class="form-content">
            <div class="form-group">
              <div class="col-6 inline-block p-0">
              <div class="text-theme text-left">{{'root.new-password: Password' | translate}}</div>
              <input #password type="password" class="form-control rounded input-lg"
                     formControlName="password">
            </div>
            </div>
            <div class="form-group">
              <div class="col-6 inline-block p-0">
              <div class="text-left text-theme">{{'root.new-password: Verify Password' | translate}}</div>
              <input #verify type="password" class="form-control rounded input-lg"
                     formControlName="verify">
              <div [hidden]="!formGroup.get('verify').touched || !formGroup.get('verify').hasError('MatchPassword')"
                   class="text-danger">{{'root.new-password: Password Don\'t Match' | translate}}
              </div>
              </div>
            </div>
          </div>
          <button class="btn rounded-btn ld-ext-right col-6" [disabled]="!formGroup.valid" (click)="changePassword()"
                  [ngClass]="{'running': (working | async)}">
            {{isForgotPasswordPage ? ('root.new-password: Change Password' | translate) : ('root.new-password: Set Password' | translate)}}
            <div class="ld ld-ring ld-spin"></div>
          </button>
          <div class="mt-2">
            <a class="link text-14" [routerLink]="'/auth/login'">{{ 'root.forgot-password: Go Back' | translate }}</a>
          </div>
        </form>
        <div class="card mt-5" *ngIf="token && passwordChanged">
          <div class="card-body text-dark">
            {{isForgotPasswordPage ? ('root.new-password: Your password is changed. Go to the' | translate) : ('root.new-password: You set your password. Go to the' | translate)}}
            <a [routerLink]="'/auth/login'">{{'root.new-password: login page' | translate}}</a>.
          </div>
        </div>
        <shared-loading-bar *ngIf="!token"></shared-loading-bar>
      </div>
    </div>
  </div>
</div>
