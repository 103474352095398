import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@app/core-module/services/api.service';
import {AlertsService} from '@app/core-module/services/alerts.service';
import {CurrentProfileState} from '@app/core-module/states/current-profile-state.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {Profile} from '@shared/models/profile';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '@app/core-module/services/language.service';
import {getLogoPath} from '@app/shared-module/utils/utils';

@Component({
  selector: 'app-tfa-setup',
  templateUrl: './tfa-setup.component.html',
  styleUrls: ['./tfa-setup.component.scss']
})
export class TfaSetupComponent implements OnInit {

  tfaFormGroup: FormGroup;

  tfaSetupData: { dataURL: string, tempSecret: string };

  successfullyInit = false;

  currentProfile: Observable<Profile>;
  logoPath: string;

  constructor(
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private alertsService: AlertsService,
    private currentProfileState: CurrentProfileState,
    private translateService: TranslateService,
    private languageService: LanguageService) {

    this.logoPath = getLogoPath();
    this.currentProfile = currentProfileState.getCurrentProfile();
    this.tfaFormGroup = new FormGroup(({
      tfaCode: new FormControl('', [Validators.required])
    }));
  }

  ngOnInit() {
    this.tfaSetup();
  }

  tfaSetup() {
    this.apiService.tfaSetup().subscribe(res => {
      this.tfaSetupData = res;
    });
  }

  tfaSetupVerify() {
    this.apiService.tfaSetupVerify({tfaCode: this.tfaFormGroup.value.tfaCode}).pipe(
    ).subscribe((res) => {
      this.alertsService.addAlert({type: 'success', message: this.translateService.instant('root.tfa-setup: Two-Factor authentication enabled')});
      this.currentProfileState.reload();
      this.successfullyInit = true;
    }, (error) => {
      if (error.error) {
        this.alertsService.addAlert({type: 'danger', message: this.translateService.instant('root.tfa-setup: Invalid verification code. Repeat the steps and try again')});
        this.tfaSetup();
      }
    });
  }

  setLanguage(lang: string) {
    this.languageService.setLanguage(lang);
  }

  logout() {
    this.authenticationService.logout();
  }

}
