import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, TransferState} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ActivateAccountComponent} from '@app/components/activate-account/activate-account.component';
import {LoginComponent} from '@app/components/login/login.component';
import {SignUpComponent} from '@app/components/sign-up/sign-up.component';
import {createTranslateLoader, SharedModule} from '@app/shared-module/shared.module';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {
  NgbAlertModule,
  NgbCarouselModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbTooltipModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import {NgBootstrapFormValidationModule} from '@mingleats/ng-bootstrap-form-validation';
import {AppComponent} from '@app/app.component';
import {CoreModule} from '@app/core-module/core.module';
import {ForgotPasswordComponent} from '@app/components/forgot-password/forgot-password.component';
import {NewPasswordComponent} from '@app/components/new-password/new-password.component';
import {environment} from 'environments/environment';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {TfaSetupComponent} from '@app/components/tfa-setup/tfa-setup.component';
import {HttpErrorInterceptorService} from '@app/core-module/services/http-error-interceptor.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NavbarService} from '@app/shared-module/utils/navbar.service';
import {ThemeService} from '@app/shared-module/theme-service/theme.service';

import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import {AppRoutingModule} from '@app/app-routing.module';
import {FaIconLibrary} from '@fortawesome/angular-fontawesome';
// Socket.IO
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {DesignModule} from '@app/design-module/design.module';

const socketConfig: SocketIoConfig = {
  url: environment.domain.replace('/api', ''), options: {
    transports: ['websocket']
  }
};

export function themeFactory(themeService: ThemeService) {
  return () => themeService.setThemeOnStart();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    ActivateAccountComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    TfaSetupComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DesignModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    NgbDropdownModule,
    NgbModalModule,
    NgbAlertModule,
    NgbTooltipModule,
    NgbCarouselModule,
    NgbTypeaheadModule,
    NgBootstrapFormValidationModule.forRoot(),
    HttpClientModule,
    CoreModule,
    RecaptchaV3Module,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, TransferState]
      }
    }),
    SocketIoModule.forRoot(socketConfig)
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true},
    ThemeService,
    {provide: APP_INITIALIZER, useFactory: themeFactory, deps: [ThemeService], multi: true},
    NavbarService,
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeEs, 'es');
    library.addIconPacks(fas, far);
  }
}
