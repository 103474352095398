import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Subject} from 'rxjs';

@Injectable()
export class AlertsService {


  private addAlert$: Subject<Alert> = new Subject<Alert>();
  private alerts: Observable<Alert>;

  constructor() {

    this.alerts = this.addAlert$.asObservable();
  }

  public getAlerts() {
    return this.alerts;
  }

  public addAlert(alert: Alert) {
    this.addAlert$.next(alert);
  }
}

export interface Alert {

  type: string;
  message: string;

}
