<div class="login-page" [@routerTransition]>
  <!--<div class="row">-->
  <!--<div class="col">-->
  <!--<div class="float-right">-->
  <!--<a class="btn right-corner-button" *ngIf="!logging && !(readingCurrentProfile | async)" [routerLink]="'/auth/sign-up'">-->
  <!--Sign Up-->
  <!--</a>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->
  <div class="row justify-content-center h-100 m-0">
    <div class="col-6 p-0 align-self-center left-bg">
      <div class="col-12">
        <img [src]="logoPath" width="150px" class="user-avatar" />
          <div class="mt-4 text-theme header-font">{{'root.login: Accounts Management Tool' | translate}}</div>
    </div>
    </div>
    <div class="col-6 p-0">
      <div class="row justify-content-end bg-theme m-0">
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/americanFlag.svg" height="14px" width="20px"/></span><a class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('en')">English</a>
        </div>
        <div class="col-auto p-3">
          <span class="mr-2"><img src="assets/flags/spanishFlag.svg" height="14px" width="20px"/></span><a class="text-theme text-12" href="javascript:void(0)" (click)="setLanguage('es')">Spanish</a>
        </div>
      </div>
      <div class="full-height bg-theme">
        <div class="col-12">
      <form role="form" class="pb-2" [formGroup]="formGroup" (validSubmit)="login()">
        <div class="form-content">
          <div class="form-group justify-content-center" *ngIf="!tfaFlag">
            <div class="col-6 inline-block p-0 m-0">
              <div class="text-left text-theme">{{'root.login: Email' | translate}}</div>
              <input #email type="text" class="form-control rounded input-lg"
                formControlName="email">
            </div>
          </div>
          <div class="form-group justify-content-center" *ngIf="!tfaFlag">
            <div class="col-6 inline-block p-0 m-0">
                <div class="text-left text-theme">{{'root.login: Password' | translate}}</div>
                <input #password type="password" class="form-control rounded input-lg"
                  formControlName="password">
                </div>
            </div>
          <div class="form-group" *ngIf="tfaFlag">
          <div class="col-6 inline-block p-0 m-0">
            <div class="text-dark text-left">{{'root.login: Two-factor verification code' | translate}}</div>
            <input #tfaCode type="text" class="form-control rounded input-lg" formControlName="tfaCode">
          </div>
          </div>
          <div class="text-danger" *ngIf="showInvalidCredentialsMessage">{{'root.login: Invalid credentials' | translate}}</div>
            <div class="text-danger" *ngIf="showInvalidTfaCode">{{'root.login: Wrong Two-factor authentication code!' | translate}}</div>
          <div class="text-danger" *ngIf="showErrorOnLoggingMessage">{{'root.login: Error while logging!' | translate}}</div>
        </div>
        <div class="form-group justify-content-center">
          <button type="submit" class="btn col-6 rounded-btn ld-ext-right"
                  [ngClass]="{'running': logging || (readingCurrentProfile | async)}"
                  [disabled]="logging || (readingCurrentProfile | async)">
                  {{'root.login: Login' | translate}}
            <div class="ld ld-ring ld-spin"></div>
          </button>
        </div>
      </form>
          <a class="link text-14" [routerLink]="'/auth/forgot-password'">{{'root.login: Forgot Your Password?' | translate}}</a>
        </div>
      </div>
    </div>
  </div>

</div>
