import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, take} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from '@app/core-module/services/authentication.service';
import {catchError, filter, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {
  refreshingToken = false;
  accessTokenSubject = new BehaviorSubject<string>(null);

  constructor(
    private authenticationService: AuthenticationService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401) {
          throw error; // we intercept only authentication errors
        }
        if (req.url.includes('/auth/token')) { // if /auth/token (this is the endpoint to refresh token) also got 401,
                                              // that means the user will have to login again
          this.authenticationService.logout();
          throw error;
        }
        if (
          req.url.includes('/auth/') &&
          !req.url.includes('/auth/token') &&
          !req.url.includes('/auth/current-user')
        ) {
          throw error; // if a user tries to log in with wrong credentials,
                       // he should really get the 401 response, instead of sending request with refreshToken and logging out if it fails
        }
        return this.handle401Error(req, error, next);
      })
    );
  }

  addNewAccessTokenToRequest(req: HttpRequest<any>, newAccessToken: string): HttpRequest<any> {
    if (!newAccessToken) {
      return req;
    }
    return req.clone({setHeaders: {Authorization: `Bearer ${newAccessToken}`}});
  }

  private handle401Error(request: HttpRequest<any>, error: HttpErrorResponse, next: HttpHandler) {
    if (!this.refreshingToken) {
      this.refreshingToken = true;
      return this.authenticationService.refreshAccessToken().pipe(
        switchMap((response) => {
          this.refreshingToken = false;
          this.accessTokenSubject.next(response.accessToken);
          this.authenticationService.updateAccessToken();
          return next.handle(this.addNewAccessTokenToRequest(request, response.accessToken));
        }),
        catchError(e => {
          this.refreshingToken = false;
          throw e;
        })
      );
    } else {
      return this.accessTokenSubject.pipe(
        filter(it => it != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addNewAccessTokenToRequest(request, token));
        })
      );
    }

    return next.handle(request);
  }



}
